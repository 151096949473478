import { all } from 'redux-saga/effects';
// import _merge from 'lodash/merge';
// tuan will remove
import watchAuth from 'providers/AuthProvider/saga';
import coreSaga from 'core/store/saga';
import Plugins from 'plugins';
// import Extensions from 'extensions';
// PLOP: Import sagas below -- DO NOT ERASE
import watchNotification from 'providers/NotificationProvider/saga';
import watchAdminUser from 'providers/AdminUserProvider/saga';
import watchCompany from 'providers/CompanyProvider/saga';
import watchUser from 'providers/UserProvider/saga';
import watchSimulation from 'providers/SimulationProvider/saga';
import watchProject from 'providers/ProjectProvider/saga';
import watchMessage from 'providers/MessageProvider/saga';
import watchContact from 'providers/ConsultationProvider/saga';

export default function* rootSaga(): Generator {
  const originalSaga = [
    watchAuth(),
    // PLOP: Use sagas below -- DO NOT ERASE
    watchNotification(),
    watchAdminUser(),
    watchCompany(),
    watchUser(),
    watchSimulation(),
    watchProject(),
    watchMessage(),
    watchContact(),
  ];
  // const allSaga = _merge(Plugins.saga, Extensions.saga);
  const allSaga = Plugins.saga;
  const extendedSagas: any = [];
  for (const pluginSagaIndex in allSaga) {
    extendedSagas.push(allSaga[pluginSagaIndex]);
  }
  const sagas = [...extendedSagas, ...originalSaga, ...coreSaga];
  yield all(sagas);
}
