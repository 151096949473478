import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
// import _merge from 'lodash/merge';
// import lang from 'core/providers/LangProvider/slice';
import auth from 'providers/AuthProvider/slice';
import general from 'providers/GeneralProvider/slice';
import coreReducer from 'core/store/reducer';
import Plugins from 'plugins';
// import Extensions from 'extensions';
// PLOP: Import reducers below -- DO NOT ERASE
import notification from 'providers/NotificationProvider/slice';
import adminUser from 'providers/AdminUserProvider/slice';
import company from 'providers/CompanyProvider/slice';
import user from 'providers/UserProvider/slice';
import simulation from 'providers/SimulationProvider/slice';
import project from 'providers/ProjectProvider/slice';
import message from 'providers/MessageProvider/slice';
import consultation from 'providers/ConsultationProvider/slice';

const createRootReducer = (history: History): Reducer => {
  const originalReducer = {
    router: connectRouter(history),
    // lang,
    auth,
    general,
    // PLOP: Use reducers below -- DO NOT ERASE
    notification,
    adminUser,
    company,
    user,
    simulation,
    project,
    message,
    consultation,
  };
  // const allReducer = _merge(Plugins.slice, Extensions.slice);
  const reducer = {
    // ...allReducer,
    ...Plugins.slice,
    ...originalReducer,
    ...coreReducer,
  };
  return combineReducers(reducer);
};
export default createRootReducer;
