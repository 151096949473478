import i18n from 'i18n';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const USER_ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  STAFF: 'STAFF',
  PRESIDENT: 'PRESIDENT',
};

export const MODAL_TYPES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const BADGE_COLOR = {
  ACCOUNT_STATUS: {
    ACTIVE: {
      text: 'userStatus:ACTIVE',
      color: 'green',
    },
    INACTIVE: {
      text: 'userStatus:INACTIVE',
      color: 'gray',
    },
  },
};

export const LAGUANGE_LIST = ['en', 'jp'];

export const langOptions = [
  {
    label: '日本語',
    value: 'jp',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const SIMULATION_STATUS = {
  UNDER_SIMPLE_ROI: 'UNDER_SIMPLE_ROI',
  COMPLETE_SIMPLE_ROI: 'COMPLETE_SIMPLE_ROI',
  UNDER_DETAIL_ROI: 'UNDER_DETAIL_ROI',
  COMPLETE_DETAIL_ROI: 'COMPLETE_DETAIL_ROI',
};

export const DETAIL_SIMULATION_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const PROJECT_STATUS = {
  STORE_DEVELOPMENT_CONTRACT: 'STORE_DEVELOPMENT_CONTRACT',
  STORE_DEVELOPMENT: 'STORE_DEVELOPMENT',
  DESIGN_CONTRACT: 'DESIGN_CONTRACT',
  DESIGN: 'DESIGN',
  CONSTRUCTION_CONTRACT: 'CONSTRUCTION_CONTRACT',
  CONSTRUCTION: 'CONSTRUCTION',
  COMPLETE_CONTRACT: 'COMPLETE_CONTRACT',
  COMPLETE: 'COMPLETE',
};

export const PROJECT_FILE_TYPE = {
  SCHEDULE_WHOLE: 'SCHEDULE_WHOLE',
  QUOTATION_STORE_DEVELOPMENT: 'QUOTATION_STORE_DEVELOPMENT',
  CONTRACT_STORE_DEVELOPMENT: 'CONTRACT_STORE_DEVELOPMENT',
  QUESTIONNAIRE_STORE_DEVELOPMENT: 'QUESTIONNAIRE_STORE_DEVELOPMENT',
  SKELTON_STORE_DEVELOPMENT: 'SKELTON_STORE_DEVELOPMENT',
  MASTERPLAN_STORE_DEVELOPMENT: 'MASTERPLAN_STORE_DEVELOPMENT',
  QUOTATION_DESIGN_CONTRACT: 'QUOTATION_DESIGN_CONTRACT',
  CONTRACT_DESIGN_CONTRACT: 'CONTRACT_DESIGN_CONTRACT',
  INVOICE_DESIGN_CONTRACT: 'INVOICE_DESIGN_CONTRACT',
  SCHEDULE_DESIGN: 'SCHEDULE_DESIGN',
  DESIGN_DESIGN: 'DESIGN_DESIGN',
  BASIC_DIAGRAM_DESIGN: 'BASIC_DIAGRAM_DESIGN',
  QUOTATION_CONSTRUCTION_CONTRACT: 'QUOTATION_CONSTRUCTION_CONTRACT',
  CONTRACT_CONSTRUCTION_CONTRACT: 'CONTRACT_CONSTRUCTION_CONTRACT',
  INVOICE_CONSTRUCTION_CONTRACT: 'INVOICE_CONSTRUCTION_CONTRACT',
  SCHEDULE_CONSTRUCTION: 'SCHEDULE_CONSTRUCTION',
  DOCS_COMPLETE: 'DOCS_COMPLETE',
};

export const CONVERSATION_LIMIT = 50;

export enum RoomType {
  AR = 'AR',
  AD = 'AD',
  ARD = 'ARD',
}

export const MOMENT_FORMAT = {
  MONTH_YEAR_SHORT: 'MM/YY',
  YEAR_MONTH_DATE: 'YYYY/MM/DD',
  YEAR_MONTH: 'YYYY/MM',
  YEAR_MONTH_DASH: 'YYYY-MM',
  YEAR_MONTH_DATE_HOUR: 'YYYY/MM/DD HH:mm',
  YEAR_MONTH_DATE_JP: 'YYYY年MM月DD日',
  YEAR_MONTH_DATE_HOUR_JP: 'YYYY年MM月DD日 HH:mm',
};
export enum MESSAGE_TYPE {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export enum USER_TYPE {
  OPERATOR = 'OPERATOR',
  USER = 'USER',
  CUSTOMER = 'CUSTOMER',
}
export const MESSAGE_LIMIT = 20;

export const FIREBASE_DOC_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const Regex = {
  PASSWORD_POLICY:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]{8,}$/,
  KATAKANA: /^[ｧ-ﾝﾞﾟァ-・ヽヾ゛゜ー()-.（-）]+$/,
  // eslint-disable-next-line max-len
  URL: /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
  PASSWORD: /^[a-zA-Z0-9!@#$%^&*-?_]{8,}$/,
  PHONE: /^[0-9]{10,13}$/,
  WHITESPACE: /\s/,
  EMAIL:
    // eslint-disable-next-line no-useless-escape, no-control-regex, max-len
    /[\s]{0,}((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))[\s]{0,}$/,
};

export const CUSTOMER_CATEGORY = {
  CORPORATION: 'CORPORATION',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const DESIGNER_CONSTRUCTION_CONTRACT = {
  CONSTRUCTION_FIXED: 'CONSTRUCTION_FIXED',
  UNDER_ESTIMATION: 'UNDER_ESTIMATION',
  DESIGNER_FIXED: 'DESIGNER_FIXED',
  NONE: 'NONE',
};

export const PRIORITY = {
  S: 'S',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};

export const BUSINESS_STATUS = {
  CALL_NOT_CONNECTED: 'CALL_NOT_CONNECTED',
  CALL_NO_NEEDS: 'CALL_NO_NEEDS',
  AFTER_CALL_NOT_CONNECTED: 'AFTER_CALL_NOT_CONNECTED',
  NEW_MEETING_SCHEDULED: 'NEW_MEETING_SCHEDULED',
  NEW_MEETING_DECIDED: 'NEW_MEETING_DECIDED',
  AFTER_MEETING_NOT_CONNECTED: 'AFTER_MEETING_NOT_CONNECTED',
  NEW_MEETING_DONE: 'NEW_MEETING_DONE',
  CONTRACT_NEGOTIATION: 'CONTRACT_NEGOTIATION',
  REVIVAL_NEGOTIATION_WAITING: 'REVIVAL_NEGOTIATION_WAITING',
  REVIVAL_MEETING_DONE: 'REVIVAL_MEETING_DONE',
  STORE_DEVELOPMENT_CONTRACT: 'STORE_DEVELOPMENT_CONTRACT',
  LICENSE_CONTRACT: 'LICENSE_CONTRACT',
};

export const INQUIRY_NEEDS = {
  FIND_INTERIOR_COMPANY: 'FIND_INTERIOR_COMPANY',
  PROPERTY_SELECTION_ASSISTANCE: 'PROPERTY_SELECTION_ASSISTANCE',
  PROPERTY_INTRODUCTION: 'PROPERTY_INTRODUCTION',
  RESTAURANT_STARTUP_GENERAL: 'RESTAURANT_STARTUP_GENERAL',
  FUND_RAISING: 'FUND_RAISING',
};

export const APPEAL_POINT = {
  FULL_SUPPORT_BEFORE_OPENING: 'FULL_SUPPORT_BEFORE_OPENING',
  MAKINO_RECORDS: 'MAKINO_RECORDS',
  CHAIN_STORE_SYSTEM_FOR_INDIVIDUAL: 'CHAIN_STORE_SYSTEM_FOR_INDIVIDUAL',
  FAILURE_FREE_STORE_DEVELOPMENT: 'FAILURE_FREE_STORE_DEVELOPMENT',
  CHEAP_ALCOHOL_SUPPLY: 'CHEAP_ALCOHOL_SUPPLY',
  DETAILED_PRODUCT_DESCRIPTION: 'DETAILED_PRODUCT_DESCRIPTION',
  ON_SITE_VISIT_ASSISTANCE: 'ON_SITE_VISIT_ASSISTANCE',
  HYBRID_INDEPENDENT_AND_FC: 'HYBRID_INDEPENDENT_AND_FC',
  FUND_RAISING_SUPPORT: 'FUND_RAISING_SUPPORT',
  QUICKEST_STORE_OPENING: 'QUICKEST_STORE_OPENING',
  HIGH_PROFIT_STORE_KNOW_HOW: 'HIGH_PROFIT_STORE_KNOW_HOW',
  LONG_TERM_STORE_MANAGEMENT_KNOW_HOW: 'LONG_TERM_STORE_MANAGEMENT_KNOW_HOW',
  OTHER_SERVICES_COSTLY_CONSULTING_FOR_50K: 'OTHER_SERVICES_COSTLY_CONSULTING_FOR_50K',
  WANT_TO_ASK_ABOUT_OPENING: 'WANT_TO_ASK_ABOUT_OPENING',
  WANT_TO_CREATE_BUSINESS_PLAN: 'WANT_TO_CREATE_BUSINESS_PLAN',
};

export const CUSTOMER_AGE = {
  TEENS: 'TEENS',
  TWENTIES: 'TWENTIES',
  THIRTIES: 'THIRTIES',
  FORTIES: 'FORTIES',
  FIFTIES: 'FIFTIES',
  SIXTIES: 'SIXTIES',
  SEVENTIES: 'SEVENTIES',
  EIGHTIES: 'EIGHTIES',
  NINETIES: 'NINETIES',
};

export const CUSTOMER_GENDER = {
  MEN: 'MEN',
  WOMEN: 'WOMEN',
  OTHER: 'OTHER',
};

export const ACCESS_SOURCE = {
  SEO_SIMULATION: 'SEO_SIMULATION',
  SEO_CONTACT: 'SEO_CONTACT',
  SOUGYOU: 'SOUGYOU',
  TENBOSU: 'TENBOSU',
  SBI: 'SBI',
  FOOD_CONNECTION: 'FOOD_CONNECTION',
  CHIJOU_SHOUKAI: 'CHIJOU_SHOUKAI',
  CORPORATION: 'CORPORATION',
  INDIVIDUAL: 'INDIVIDUAL',
  TENANTA: 'TENANTA',
  ZENKEN: 'ZENKEN',
  INOUE: 'INOUE',
  OTHERS: 'OTHERS',
};

export const ACCESS_SOURCE_DETAIL = {
  ORGANIC: 'ORGANIC',
  GOOGLE_NAISOU: 'GOOGLE_NAISOU',
  GOOGLE_KAIGYOU: 'GOOGLE_KAIGYOU',
  YAHOO_NAISOU: 'YAHOO_NAISOU',
  YAHOO_KAIGYOU: 'YAHOO_KAIGYOU',
  DIRECT: 'DIRECT',
  YAHOO: 'YAHOO',
  GOOGLE: 'GOOGLE',
  OTHERS: 'OTHERS',
};

export const yesNoOptions = [
  { label: i18n.t('globally:no'), value: false },
  { label: i18n.t('globally:yes'), value: true },
];
